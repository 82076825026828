@import '~@forevolve/bootstrap-dark/scss/toggle-bootstrap';
@import '~@forevolve/bootstrap-dark/scss/toggle-bootstrap-dark';
@import "./prism";

html, body, #root {
  height: 100%;
}

body {
  overflow-y: scroll;
}

#root {
  display: flex;
  flex-direction: column;
}

.wrapper {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

.toast-container {
  z-index: 99;
  right: 0;
  min-width: 300px;
}

.bootstrap {
  .col-editor {
    background: #efefef;
  }

  footer {
    .nav-item-themed {
      color: slategray;
    }
  }

  .markdown {
    pre, pre[class*="language-"] {
      margin-bottom: 2em;
      margin-top: 2em;
    }
  }

  blockquote {
    background: #f9f9f9;
  }
}

.bootstrap-dark {
  .col-editor {
    background: #333;
  }

  footer {
    .nav-item-themed {
      color: gray;
    }
  }

  pre {
    color: #efefef;
  }

  .markdown {
    pre, pre[class*="language-"] {
      margin-bottom: 2em;
      margin-top: 2em;
    }

    .diagram {
      background: black;
    }
  }

  blockquote {
    background: #343a40;
  }
}

blockquote {
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

#stackblitz-container {
  border: none;
  height: calc(100vh - 80px);
}

.markdown {
  pre {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  h1, h2 {
    border-bottom: 1px solid $border-color;
    margin-bottom: 1em;
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
  }

  h3, h4, h5, h6 {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .bootstrap-dark .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

.bootstrap-dark .btn:focus, .bootstrap-dark .btn.focus,
.bootstrap .btn:focus, .bootstrap .btn.focus {
  box-shadow: none;
}

.clickable {
  cursor: pointer;
}
